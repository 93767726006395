import { graphql, StaticQuery } from 'gatsby'
import React from 'react'

import PreviewGrid from '../components/preview-grid'
import Seo from '../components/seo'
import { responsiveTitle1 } from '../components/typography.module.css'
import Layout from '../containers/layout'
import { filterOutDocsWithoutSlugs, mapEdgesToNodes } from '../lib/helpers'

const LazyHacksPage = ({ data, errors }) => {
  const lazyHacksNodes =
    data && data.lazyHacks && mapEdgesToNodes(data.lazyHacks).filter(filterOutDocsWithoutSlugs)

  return (
    <Layout>
      <Seo title='Lazy hacks' />
      <h1 hidden>Harry's lazy hacks page</h1>
      <h2 className={responsiveTitle1}>Lazy hacks</h2>
      {lazyHacksNodes && lazyHacksNodes.length > 0 && <PreviewGrid type='lazy-hacks' nodes={lazyHacksNodes} />}
    </Layout>
  )
}

export default function LazyHacksPageQuery () {
  return (
    <StaticQuery
      query={graphql`
        query LazyHacksPageQuery {
          lazyHacks: allSanityLazyhack(
            sort: { fields: [publishedAt], order: DESC }
            filter: { publishedAt: {ne: null}, slug: { current: { ne: null } } }
          ) {
            edges {
              node {
                id
                publishedAt
                mainImage {
                  asset {
                    _id
                  }
                  alt
                }
                title
                _rawExcerpt
                slug {
                  current
                }
              }
            }
          }
        }
      `}
      render={(data) => <LazyHacksPage data={data} />}
    />
  )
}
